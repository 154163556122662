import React, { useState } from "react";
import { Helmet } from 'react-helmet-async';
import Banner from "../../Components/StudyAbroad/Banner";
import styled from "styled-components";
import UniversityList from "../../Components/StudyAbroad/Universities/index";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import LottieLoading from "../../Components/Loaders/LottieLoading";

const Universities = ({ props }) => {
  const { t } = useTranslation();
  const { applyTo } = props;
  const { aId } = useParams();

  // Fetch agent data if applyTo is "agents"
  const { data: agentData } = useQuery({
    queryKey: ["agent", aId],
    queryFn: async () => {
      if (applyTo === "agents") {
        const response = await axios.get(`/agents/${aId}`);
        return response.data;
      }
      return null;
    },
    enabled: applyTo === "agents",
  });

  const [filterValues, setFilterValues] = useState({
    region: [],
    country: [],
    speciality: [],
    degree: [],
    price: 0,
    gs: "",
    search: "",
    page: 1,
    page_size: 5,
  });

  const queryValue = {};
  filterValues.region.length > 0 &&
    (queryValue.region = filterValues.region.join(","));
  filterValues.country.length > 0 &&
    (queryValue.country = filterValues.country.join(","));
  filterValues.speciality.length > 0 &&
    (queryValue.speciality = filterValues.speciality.join(","));
    filterValues.degree.length > 0 &&
    (queryValue.degree = filterValues.degree.join(","));
  filterValues.price > 0 && (queryValue.price = filterValues.price);
  filterValues.gs && (queryValue.is_scholarship = filterValues.gs);
  filterValues.search && (queryValue.search = filterValues.search);
  filterValues.page > 0 && (queryValue.page = filterValues.page);
  filterValues.page_size > 0 && (queryValue.page_size = filterValues.page_size);

  const { data: universities, isLoading: universityLoading } = useQuery({
    queryKey: ["universities", queryValue],
    queryFn: async () =>
      (
        await axios.get(`/university/filteringforuniversity/`, {
          params: queryValue,
        })
      ).data,
  });
  const { data: cost, isLoading: costLoading } = useQuery({
    queryKey: ["costs"],
    queryFn: async () => {
      const res = await axios.get("university/university_professions/cost/");
      return res.data;
    },
  });

  const getPageTitle = () => {
    if (applyTo === "agents") {
      const agentName = agentData?.name || t('unknown_agent');
      return t('universities_agent_page_title', { agentName });
    }
    return t('universities_page_title');
  };

  const getPageDescription = () => {
    if (applyTo === "agents") {
      const agentName = agentData?.name || t('unknown_agent');
      return t('universities_agent_page_description', { agentName });
    }
    return t('universities_page_description');
  };

  if (costLoading ) {
    return <LottieLoading />;
  }

  return (
    <UniversityContainer>
      <Helmet>
        <title>{getPageTitle()}</title>
        <meta name="description" content={getPageDescription()} />
      </Helmet>

      <Banner props={{ type: "all", t }} />
      <UniversityList
        t={t}
        applyTo={applyTo}
        agentId={aId}
        universities={universities}
        cost={cost}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        universityLoading={universityLoading}
      />
    </UniversityContainer>
  );
};

export default Universities;

const UniversityContainer = styled.div``;
