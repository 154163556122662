import React from "react";
import { Group } from "@mantine/core";
import { Download } from "tabler-icons-react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CustomButton from "../Common/CustomButton";

const ProgramButtons = ({
  isAppliedProgram,
  programStatus,
  onApply,
  isLoading,
  programId,
  syllabusFile,
  xs,
  buttonWidth,
  buttonHeight,
  type,
  isLoggedin,
  allActive,
  allInactive,
  someActive,
  entryData,
  t,
}) => {
  if (type === "course") {
    return (
      <ButtonGroup>
        {!isLoggedin ? (
          <CustomButton
            component={Link}
            to="/login"
            xs={xs}
            width="100%"
            height={buttonHeight}
          >
            Log in to purchase
          </CustomButton>
        ) : allActive ? (
          <>
            <CustomButton
              component={Link}
              to={`/course/${entryData?.id}/course-content`}
              xs={xs}
              width={buttonWidth}
              height={buttonHeight}
            >
              {t("enter")}
            </CustomButton>
            <CustomButton
              variant="outline"
              leftIcon={<Download />}
              component="a"
              href={syllabusFile}
              target="_blank"
              download
              xs={xs}
              width={buttonWidth}
              height={buttonHeight}
            >
              {t("downloadSyllabus")}
            </CustomButton>
          </>
        ) : allInactive ? (
          <CustomButton
            component={Link}
            to={`/courses/${entryData?.id}/payment`}
            xs={xs}
            width="100%"
            height={buttonHeight}
          >
            {t("apply")}
          </CustomButton>
        ) : someActive ? (
          <>
            <CustomButton
              component={Link}
              to={`/course/${entryData?.id}/course-content`}
              xs={xs}
              width={buttonWidth}
              height={buttonHeight}
            >
              {t("enter")}
            </CustomButton>
            <CustomButton
              component={Link}
              to={`/courses/${entryData?.id}/payment`}
              xs={xs}
              width={buttonWidth}
              height={buttonHeight}
            >
              {t("buyOtherSections")}
            </CustomButton>
            <CustomButton
              variant="outline"
              leftIcon={<Download />}
              component="a"
              href={syllabusFile}
              target="_blank"
              download
              xs={xs}
              width={buttonWidth}
              height={buttonHeight}
            >
              {t("downloadSyllabus")}
            </CustomButton>
          </>
        ) : null}
      </ButtonGroup>
    );
  }

  // Original STEAM buttons
  return (
    <ButtonGroup>
      {programStatus === "Fail" ? (
        <CustomButton
          component={Link}
          to={`/${type}/${entryData?.id}/payment`}
          xs={xs}
          width="100%"
          height={buttonHeight}
          type={type}
        >
          Apply
        </CustomButton>
        ) : programStatus === "Success" &&  (
        <>
          <CustomButton
            type={type}
            component={Link}
            to={`/${type}/${programId}/overview`}
            xs={xs}
            width={buttonWidth}
            height={buttonHeight}
          >
            Enter
          </CustomButton>
          <CustomButton
            type={type}
            variant="outline"
            leftIcon={<Download />}
            component="a"
            href={syllabusFile}
            target="_blank"
            download
            xs={xs}
            width={buttonWidth}
            height={buttonHeight}
          >
            Download Syllabus
          </CustomButton>
        </>
      )}
    </ButtonGroup>
  );
};

export default ProgramButtons;

// ... ButtonGroup styled component ...
const ButtonGroup = styled(Group)`
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    button {
      width: 100%;
    }
  }
`;
