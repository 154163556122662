import { useContext, useState } from "react";
import styled from "styled-components";
import axiosInstance from "../Helpers/Axios";
import { Helmet, HelmetProvider } from "react-helmet-async"; // react-helmet-async'den import ediyoruz
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ProgramCard } from "./Cards/ProgramCard";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { SkeletonLoader } from "./Loaders/SkeletonLoader";
import { Button, Center, Container, Grid, Tabs, Text } from "@mantine/core";
import { motion } from "framer-motion";
import { AnimateOpa } from "./Animation/AnimateOpa";
import { ComingSoon } from "./ComingSoon";
import { BorderAll, ChevronLeft, Package } from "tabler-icons-react";
import { AppContext } from "../Helpers/Context";
import { Link } from "react-router-dom";
import InfiniteScrollComponent from "./Common/InfiniteScroll";

function Skeletons() {
  return (
    <Grid style={{width: "100%" ,marginTop: "1.5rem"}} >
      {[...Array(9)].map((e, i) => {
        return (
          <Grid.Col key={i} md={4}>
            <SkeletonLoader
              height={260}
              width="100%"
              mb={10}
              visible={true}
            />
          </Grid.Col>
        );
      })}
    </Grid>
  );
}

function Errors({ error }) {
  return (
    <Center>
      <Container my={120}>
        <Text weight={600} mr={2} align="center" size={32}>
          Error
        </Text>
        <Text size={20}>{error}</Text>
      </Container>
    </Center>
  );
}

function NoPurchasedPrograms({ to }) {
  return (
    <Container my={100}>
      <Text align="center" mb={20} size={28}>
        You haven't purchased any programs yet
      </Text>
      <Center>
        <Button
          component={Link}
          to={to}
          style={{ color: "#000", fontWeight: "500" }}
          color={"ts"}
          leftIcon={<ChevronLeft />}
        >
          Check out all the programs
        </Button>
      </Center>
    </Container>
  );
}

function AllPrograms({ props }) {
  const { contentAmount, setContentAmount, type, userId } = props;
  const base = process.env.REACT_APP_BASE_URL
  const link = type === "bootcamp" ? "/bootcamp/" : "/steam/steam/"
  const programUrl = `${base}${link}`;

  const {
    data: steams,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ["programList",type],
    queryFn: async ({ pageParam = programUrl }) => {
      const { data } = await axiosInstance.get(pageParam);
      return data;
    },
    getNextPageParam: (lastPage) => lastPage.next ?? false, // Ensure this matches your API's pagination response
  });

  let linkAll =
    type === "course"
      ? "/api/all-courses/"
      : type === "internship"
      ? "internship/activeinternshiplist"
      : type === "hackathon"
      ? "/hackathon/activate_hackatons_list/"
      : type === "steam"
      ? "/steam/steam/"
      : type === "bootcamp"
      ? "/bootcamp/"
      : "/api/game-list/";

  const {
    data: contents,
    isError,
    error,
    isLoading,
  } = useQuery(
    [linkAll, linkAll],
    async () => await axiosInstance.get(linkAll).then((res) => res.data),
    { enabled: !!type }
  );

  if (isLoading) {
    return <Skeletons />;
  }

  if (isError) {
    return <Errors error={error?.message} />;
  }

  if (contents?.length === 0) {
    return <ComingSoon />;
  }

  return (
    <motion.div
      animate={{ y: [100, 0] }}
      transition={{ ease: "easeOut", duration: 0.3 }}
      style={{ paddingTop: "1.5rem" }}
      className="contents-container"
    >
      {type === "steam" || type === "bootcamp" ? (
        <InfiniteScrollComponent
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        >
          <Grid>
            {steams?.pages?.map((page) =>
              page?.results?.map((content) => (
                <ProgramCard
                  key={content.id}
                  props={{
                    link:
                      type === "course"
                        ? `/course/${content.id}`
                        : type === "hackathon"
                        ? `/hackathons/${content?.id}`
                        : type === "steam"
                        ? `/steam/${content?.id}`
                        : type === "bootcamp"
                        ? `/bootcamps/${content?.id}`
                        : `/internships/${content.id}`,
                    image:
                      type === "steam" ? content?.steam_image : type === "bootcamp" ? content?.bootcamp_image : content.image,
                    title:
                      type === "steam" ? content.steam_name : type === "bootcamp" ? content.bootcamp_name : content.title,
                    price: type === "steam" ? content.price : content.price,
                    language:
                      type === "steam" ? content.language : content.language,
                    duration:
                      type === "steam" ? content.duration_time : content.duration,
                  }}
                />
              ))
            )}
          </Grid>
        </InfiniteScrollComponent>
      ) : (
        <Grid  >
          {contents?.slice(0, contentAmount)?.map((content) => (
            <ProgramCard
              key={content.id}
              props={{
                link:
                    type === "course"
                      ? `/course/${content.id}`
                      : type === "hackathon"
                      ? `/hackathons/${content?.id}`
                      : type === "steam"
                      ? `/steam/${content?.id}`
                      : `/internships/${content.id}`,
                  image:
                    type === "steam" ? content?.steam_image : content.image,
                  title: type === "steam" ? content.steam_name : content.title,
                  price: type === "steam" ? content.price : content.price,
                  language:
                    type === "steam"
                      ? content.language
                      : content.language.language,
                  duration:
                    type === "steam" ? content.duration_time : content.duration,
                }}
              />
          ))}
        </Grid>
      )}
      {contents[contentAmount] && (
        <Center mb={30}>
          <Button
            px={50}
            color={"dark.7"}
            onClick={() => setContentAmount(contentAmount + 9)}
          >
            See more
          </Button>
        </Center>
      )}
    </motion.div>
  );
}

function PurchasedPrograms({ props }) {
  const { contentAmount, setContentAmount, type, userId } = props;
  const base = process.env.REACT_APP_BASE_URL
  const link = type === "bootcamp" ? "/bootcamp/applied" : "/steam/steam/applied";
  const programUrl = `${base}${link}`;

  const {
    data: programs,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ["programList-applied",type],
    queryFn: async ({ pageParam = programUrl }) => {
      const { data } = await axiosInstance.get(pageParam);
      return data;
    },
    getNextPageParam: (lastPage) => lastPage.next ?? false, // Ensure this matches your API's pagination response
  });

  const { user } = useContext(AppContext);
  let linkPur =
    type === "course"
      ? "/api/studentapplycourselist/"
      : type === "internship"
      ? "/internship/studentapplyinternlist/"
      : type === "hackathon"
      ? `/hackathon/hackathonjoineduserid/${user?.id}/`
      : type === "steam"
      ? `/steam/steam/applied`
      : type === "bootcamp"
      ? "/bootcamp/applied"
      : type === "game" && "/api/game-list/";

  const {
    data: contents,
    isError,
    error,
    isLoading,
  } = useQuery(
    [linkPur],
    async () => await axiosInstance.get(linkPur).then((res) => res.data),
    { enabled: !!type }
  );

  const data = (type === "steam" || type === "bootcamp") ? contents?.results : contents;

  if (isLoading) {
    return <Skeletons />;
  }

  if (isError) {
    return <Errors error={error?.message} />;
  }

  if (data?.length === 0) {
    return (
      <NoPurchasedPrograms to={type === "course" ? "/courses" : type === "bootcamp" ? "/bootcamps" : "/steam"} />
    );
  }

  return (
    <motion.div
      animate={{ y: [100, 0] }}
      transition={{ ease: "easeOut", duration: 0.3 }}
      style={{ paddingTop:"1.5rem" }}
      className="contents-container"
    >
      {(type === "steam" || type === "bootcamp") ? (
        <InfiniteScrollComponent
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        >
          <Grid>
            {programs?.pages?.map((page) =>
              page?.results?.map((content) => (
                <ProgramCard
                  key={content.id}
                  props={{
                    link:
                        type === "course"
                          ? `/course/${content.id}`
                          : type === "hackathon"
                          ? `/hackathons/${content?.id}`
                          : type === "steam"
                          ? `/steam/${content?.id}`
                          : type === "bootcamp"
                          ? `/bootcamps/${content?.id}`
                          : `/internships/${content.id}`,
                      image:
                        type === "steam" ? content?.steam_image : type === "bootcamp" ? content?.bootcamp_image : content.image,
                      title:
                        type === "steam" ? content.steam_name : type === "bootcamp" ? content.bootcamp_name : content.title,
                      price: type === "steam" ? content.price : content.price,
                      language:
                        type === "steam" ? content.language : content.language,
                      duration:
                        type === "steam" ? content.duration_time : content.duration,
                    }}
                />
              ))
            )}
          </Grid>
        </InfiniteScrollComponent>
      ) : (
        <Grid>
          {contents?.slice(0, contentAmount)?.map((content) => (
            <ProgramCard
              key={content.id}
              props={{
                link:
                    type === "course"
                      ? `/course/${content.id}`
                      : type === "hackathon"
                      ? `/hackathons/${content?.id}`
                      : type === "steam"
                      ? `/steam/${content?.id}`
                      : `/internships/${content.id}`,
                  image:
                    type === "steam" ? content?.steam_image : content.image,
                  title: type === "steam" ? content.steam_name : content.title,
                  price: type === "steam" ? content.price : content.price,
                  language:
                    type === "steam"
                      ? content.language
                      : content.language.language,
                  duration:
                    type === "steam" ? content.duration_time : content.duration,
                }}
              />
          ))}
        </Grid>
      )}
      {contents[contentAmount] && (
        <Center mb={30}>
          <Button
            px={50}
            color={"dark.7"}
            onClick={() => setContentAmount(contentAmount + 9)}
          >
            See more
          </Button>
        </Center>
      )}
    </motion.div>
  );
}

export const AllContents = ({ props }) => {
  const { defaultValue, type } = props;
  const { t } = useTranslation();

  const [contentAmount, setContentAmount] = useState(9);

  const { user } = useContext(AppContext);

  let location = useLocation();

  // Sayfa başlığını belirlemek için bir fonksiyon
  const getPageTitle = () => {
    switch (type) {
      case "course":
        return t("Courses");
      case "internship":
        return t("Internships");
      case "hackathon":
        return t("Hackathons");
      case "steam":
        return t("STEAM");
      case "bootcamp":
        return t("Bootcamps");
      case "game":
        return t("Games");
      default:
        return t("All Contents");
    }
  };

  if (location.pathname === "/games") {
    return (
      <HelmetProvider>
        <Helmet>
          <title>{`${t("Coming Soon")} | Taskool`}</title>
        </Helmet>
        <ComingSoon margin={500} />
      </HelmetProvider>
    );
  }

  if (!user?.id) {
    return (
      <HelmetProvider>
        <AnimateOpa>
          <Helmet>
            <title>{`${getPageTitle()} | Taskool`}</title>
          </Helmet>
          <ContentStyle>
            <AllPrograms
              props={{
                contentAmount,
                setContentAmount,
                type,
                userId: user?.id,
              }}
            />
          </ContentStyle>
        </AnimateOpa>
      </HelmetProvider>
    );
  }

  return (
    <HelmetProvider>
      <AnimateOpa>
        <Helmet>
          <title>{`${getPageTitle()} | Taskool`}</title>
        </Helmet>
        <ContentStyle>
          <Tabs
            radius={".75rem"}
            variant="pills"
            defaultValue={defaultValue}
            styles={(theme) => ({
              root: { width: "100%", padding: 0 },
              tabsList: { gap: 0, padding: 0 },
              tab: {
                backgroundColor: "transparent",
                padding: 0,
                border: "1px solid #029199",
                color: "#029199",
                "&[data-active]": {
                  backgroundColor: "#029199",
                  color: "#fff",
                },
              },
              panel: { margin: 0, padding: 0 },
            })}
          >
            <Tabs.List
              grow
              style={{
                borderRadius: ".75rem",
                border: "1px solid #dee2e6",
              }}
            >
              <Tabs.Tab
                style={{ borderRadius: ".75rem 0 0 .75rem" }}
                p={8}
                value="all"
                color={"white"}
                icon={<BorderAll />}
              >
                <Text size={17} weight={500}>
                  {t("all")}
                </Text>
              </Tabs.Tab>
              <Tabs.Tab
                style={{ borderRadius: "0 .75rem .75rem 0" }}
                p={8}
                value="purchased"
                color={"white"}
                icon={<Package />}
              >
                <Text size={17} weight={500}>
                  {t("purchased")}
                </Text>
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="all" style={{}}>
              <AllPrograms
                props={{
                  contentAmount,
                  setContentAmount,
                  type,
                  userId: user?.id,
                }}
              />
            </Tabs.Panel>

            <Tabs.Panel value="purchased" style={{}}>
              <PurchasedPrograms
                props={{ contentAmount, setContentAmount, type }}
              />
            </Tabs.Panel>
          </Tabs>
        </ContentStyle>
      </AnimateOpa>
    </HelmetProvider>
  );
};

const ContentStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: left;
  padding-bottom: 3.5rem;
  .contents {
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .contents-container {
    width: 100%;
  }
  @media (max-width: 1376px) {
    width: 95%;
    margin: 0 auto;
  }

`;

