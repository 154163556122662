import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import ProgramInfo from "../../Components/Programs/ProgramInfo";
import ProgramMap from "../../Components/Programs/ProgramMap";
import { ProgramFiller } from "../../Components/Programs/ProgramFiller";
import AboutAuthor from "../../Components/Programs/AboutAuthor";
import { useMediaQuery } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "../../Helpers/Axios";
import LottieLoading from "../../Components/Loaders/LottieLoading";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { AppContext } from "../../Helpers/Context";
import NotificationModal from "../../Components/Modals/NotificationModal";

const SingleProgram = ({ props }) => {
  const { isTaskool, type } = props;
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const [modalOpened, setModalOpened] = useState(!!status || false);
  const xs = useMediaQuery("(max-width: 768px)");
  const [isAppliedProgram, setIsAppliedProgram] = useState(false);
  const [programStatus, setProgramStatus] = useState("");
  const { user } = useContext(AppContext);

  let links =
    type === "course"
      ? {
          program_detail: `/api/course/${id}/`,
        }
      : type === "bootcamp"
      ? {
          program_detail: `/bootcamp/${id}/`,
        }
      : {
          program_detail: `/steam/steam/${id}/`,
        };

  const { data: program, isLoading } = useQuery({
    queryKey: ["program", id],
    queryFn: async () => {
      const response = await axios.get(`${links.program_detail}`);
      return type === "course" ? response.data[0] : response.data;
    },
    enabled: !!id,
  });

  let link =
    type === "course"
      ? {
          successfuly_paid: `/api/successfuly_paid_course/${program?.id}/`,
          section_list: `/api/section-detail/${program?.id}/`,
        }
      : type === "bootcamp"
      ? {
          program : 'bootcamp/',
          successfuly_paid: `/bootcamp/${program?.id}/apply`,
        }
      : {
          program : 'steam/steam/',
          successfuly_paid: `/steam/steam/${program?.id}/apply`,
        };

  const { isLoading: appliedLoading } = useQuery({
    queryKey: ["isApplied", program?.id],
    queryFn: async () => {
      const response = await axios.get(`${link.successfuly_paid}`);
      return response.data;
    },
    onSuccess: (data) => {
      setIsAppliedProgram(true);
      setProgramStatus(data?.status);
    },
    onError: () => {
      setIsAppliedProgram(false);
    },
    enabled: !!program?.id, // Moved inside the options object
  });

  const { data: sectionList } = useQuery(
    ["sections list", program?.id],
    async () => {
      return await axios.get(link.section_list).then((res) => res.data);
    },
    {
      enabled: !!program?.id,
    }
  );
  const navigate = useNavigate();

  const closeModal = () => {
    setModalOpened(false);
    if (status === "Accepted") {
      navigate(`/${type === "bootcamp" ? "bootcamps" : type}/${id}`);
    }
  };

  const title =
    type === "steam"
      ? program?.steam_name
      : type === "bootcamp"
      ? program?.bootcamp_name
      : program?.title;
  if (isLoading || appliedLoading) return <LottieLoading />;

  return (
    <section>
      <Helmet>
        <title>{`${title || t("program")} | Taskool`}</title>
        <meta
          name="description"
          content={
            parse(`${program && program[`${type}_about`]}`) ||
            t("program_description")
          }
        />
      </Helmet>
      {status && (
        <NotificationModal
          modalOpened={modalOpened}
          closeModal={closeModal}
          status={status}
        />
      )}
      <ProgramInfo
        t={t}
        user={user}
        sectionList={sectionList}
        type={type}
        xs={xs}
        data={program}
        isAppliedProgram={isAppliedProgram}
        programStatus={programStatus}
        link={link}
      />
      {(type === "steam" || type === "bootcamp") && (
        <ProgramMap xs={xs} data={program} type={type}/>
      )}
      <div
        style={{ height: "30px", width: "100%", background: "#f6f6f6" }}
      ></div>
      <ProgramFiller props={{ isTaskool, program: type }} />
      {(type === "steam" ||
        type === "bootcamp" ||
        (type === "course" && program?.teacher?.bio)) && (
        <AboutAuthor xs={xs} data={program} type={type}/>
      )}
    </section>
  );
};

export default SingleProgram;
