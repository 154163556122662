import React from "react";
import styled from "styled-components";
import { Grid, Group, Text, Title } from "@mantine/core";

const AboutAuthor = ({ xs, data,type }) => {
  const hasAuthorImage = data?.author_image_file;
  return (
    <section style={{ background: "#f6f6f6",paddingBottom: '4.5rem',paddingTop: '2.5rem' }}>
      <AboutAuthorStyle>
        {xs && (
          <Title
            mt={0}
            mb={50}
            size={xs ? 20 : 24}
            weight={500}
            color="#364152"
          >
            About Author
          </Title>
        )}
        <Grid gutter={xs ? 0 : 80}>
          {hasAuthorImage && (
            <Grid.Col sm={4}>
              <Group position="right">
                <Frame type={type}>
                  <img
                    className="author-img"
                    src={data?.author_image_file}
                    alt="author"
                  />
                </Frame>
              </Group>
            </Grid.Col>
          )}
          <Grid.Col sm={hasAuthorImage ? 8 : 12}>
            {!xs && (
              <Title mt={10} size={24} weight={500} color="#364152">
                About Author
              </Title>
            )}
            <Text
              mt={15}
              style={{ lineHeight: "30px", maxWidth: xs ? "100%" : "90%" }}
              size={16}
              weight={400}
              color="#888888"
            >
              {data?.author_about}
            </Text>
          </Grid.Col>
        </Grid>
      </AboutAuthorStyle>
    </section>
  );
};

export default AboutAuthor;

const AboutAuthorStyle = styled.section`
  max-width: 92%;
  margin: 0 auto;
  padding: 50px 0 0 0;
  background: #f6f6f6;
  @media (max-width: 768px) {
    max-width: 96%;
    padding: 0 0 50px 0;
  }
`;

const Frame = styled.div`
  width: 80%;
  height: 426px;
  border: 5px solid ${props => props.type === "steam" ? "#029199" : "#FE6027"};
  border-radius: 4px;
  position: relative;
  .author-img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 1;
    top: -30px;
    left: -30px;
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;
