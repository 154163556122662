import { ReactComponent as LeftTopSvg } from '../Assets/SVGs/steams/pathway/left-top.svg';
import { ReactComponent as LeftBottomSvg } from '../Assets/SVGs/steams/pathway/left-bottom.svg';
import { ReactComponent as RightTopSvg } from '../Assets/SVGs/steams/pathway/right-top.svg';
import { ReactComponent as RightBottomSvg } from '../Assets/SVGs/steams/pathway/right-bottom.svg';
import { ReactComponent as LeftCenterSvg } from '../Assets/SVGs/steams/pathway/left-center.svg';
import { ReactComponent as RocketSvg } from '../Assets/SVGs/steams/pathway/rocket.svg';
import { ReactComponent as HalfCircleSvg } from '../Assets/SVGs/steams/pathway/half-circle.svg';
import { ReactComponent as RightVectorSvg } from '../Assets/SVGs/steams/pathway/vector.svg';
import { ReactComponent as LeftVectorSvg } from '../Assets/SVGs/steams/pathway/left-vector.svg';
import styled from 'styled-components';

// Left Top Component
export const LeftTop = styled(LeftTopSvg)`
  #paint0_linear_3717_763 stop {
    &:first-child {
      stop-color: ${props => props.theme.gradient.start};
    }
    &:last-child {
      stop-color: ${props => props.theme.gradient.end};
    }
  }
`;

// Left Bottom Component
export const LeftBottom = styled(LeftBottomSvg)`
  #paint0_linear_3717_764 stop {
    &:first-child {
      stop-color: ${props => props.theme.gradient.start};
    }
    &:last-child {
      stop-color: ${props => props.theme.gradient.end};
    }
  }
`;

// Right Top Component
export const RightTop = styled(RightTopSvg)`
  #paint0_linear_3717_771 stop {
    &:first-child {
      stop-color: ${props => props.theme.gradient.start};
    }
    &:last-child {
      stop-color: ${props => props.theme.gradient.end};
    }
  }
`;

// Right Bottom Component
export const RightBottom = styled(RightBottomSvg)`
  #paint0_linear_3717_767 stop {
    &:first-child {
      stop-color: ${props => props.theme.gradient.start};
    }
    &:last-child {
      stop-color: ${props => props.theme.gradient.end};
    }
  }
  path[opacity="0.1"] {
    fill: ${props => props.theme.primary};
  }
`;

// Left Center Component
export const LeftCenter = styled(LeftCenterSvg)`
  path {
    fill: ${props => props.theme.primary};
  }
`;

// Rocket Component
export const Rocket = styled(RocketSvg)`
  path[fill-opacity="0.17"] {
    fill: ${props => props.theme.primary};
  }
  
  #paint0_linear_3717_612 stop {
    &:first-child {
      stop-color: ${props => props.theme.gradient.start};
    }
    &:last-child {
      stop-color: ${props => props.theme.gradient.end};
    }
  }
  
  #paint1_linear_3717_612 stop {
    stop-color: ${props => props.theme.primary};
  }
  
  #paint2_linear_3717_612 stop {
    &:first-child {
      stop-color: ${props => props.theme.gradient.start};
    }
    &:last-child {
      stop-color: ${props => props.theme.gradient.end};
    }
  }

  path[fill="#029199"] {
    fill: ${props => props.theme.primary};
  }

  path[fill="#038991"] {
    fill: ${props => props.theme.secondary};
  }

  path[fill-opacity="0.25"] {
    fill: ${props => props.theme.primary};
  }
`;

// Half Circle Component
export const HalfCircle = styled(HalfCircleSvg)`
  path {
    fill: ${props => props.theme.primary};
  }
`;

// Vector Components
export const RightVector = styled(RightVectorSvg)`
  path {
    fill: ${props => props.theme.primary};
  }
`;

export const LeftVector = styled(LeftVectorSvg)`
  path {
    fill: ${props => props.theme.primary};
  }
`; 