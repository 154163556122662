import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import { Table, Text, Paper, Grid, Center, Stack, Image, ThemeIcon } from '@mantine/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from '../../Helpers/Axios';
import LottieLoading from '../../Components/Loaders/LottieLoading';
import { ClipboardText } from 'tabler-icons-react';
import parse from "html-react-parser";


const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Score ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const StudentGrades = ({type}) => {
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();
  const tUnit = type === 'steam' ? 'week' : 'section';
  const tSubUnit = type === 'steam' ? 'day' : 'lesson';

  // Add this function to handle pie chart hover
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const { data :steamData, isLoading :steamLoading } = useQuery({
    queryKey: [`${type}-task-score`, id,type],
    queryFn: async () => {
      const res = await axios.get(`/steam/steam/applied/${id}`);
      return res.data;
    },
    enabled : type === 'steam' 
  });
  const { data: bootcampData, isLoading: bootcampLoading } = useQuery({
    queryKey: [`${type}-task-score`, id,type],
    queryFn: async () => {
      const res = await axios.get(`/bootcamp/${id}/applied`);
      return res.data;
    },
    enabled : type === 'bootcamp' 
  });
  const data = type === 'steam' ? steamData : bootcampData;
  const isLoading = type === 'steam' ? steamLoading : bootcampLoading;

  const { data: unitContents , isLoading: unitLoading } = useQuery({
    queryKey: [`${tUnit}s`,id],
    queryFn: async () => {
      const response = await axios.get(`/${type === 'steam' ? 'steam/steam': 'bootcamp'}/${id}/${tUnit}`);
      return response.data.data;
    },
  });

  const getSubUnitIndex = (unitId, subUnitId, units) => {
    if (!units) return 1; // weeks yoxdursa default 1 qaytaraq
    
    // Əvvəlcə week-i tapaq
    const unit = units.find(u => u.id === unitId);
    if (!unit) return 1;

    // Həmin week-in days_output array-ində day-in indeksini tapaq
    const subUnitIndex = unit[`${tSubUnit}s_output`].findIndex(sU => sU.id === subUnitId);
    
    // Əgər tapılıbsa index + 1 qaytaraq (0-based index-i 1-based-ə çevirmək üçün)
    // Tapılmayıbsa default 1 qaytaraq
    return subUnitIndex !== -1 ? subUnitIndex + 1 : 1;
  };


  // Create chart data using the average score from API
  const chartData = [
    { name: `${data?.completed_tasks_with_review?.scores_avarage}/100`, value: data?.completed_tasks_with_review?.scores_avarage || 0 },
    { name: 'Remaining', value: 100 - (data?.completed_tasks_with_review?.scores_avarage || 0) },
  ];

  console.log('data', data);

  if(isLoading || unitLoading) return <LottieLoading />

  if (!data?.completed_tasks_with_review?.reviews?.length) {
    return (
      <GradesStyle>
        <Helmet>
          <title>{t('student_grades_title')}</title>
          <meta name="description" content={t('student_grades_description')} />
        </Helmet>
        <Paper bg="transparent" p="md" mt="md">
          <Center style={{ minHeight: '70vh' }}>
            <Stack align="center" spacing="lg">
              <ThemeIcon size={80} radius={40} variant="light">
                <ClipboardText size={40} />
              </ThemeIcon>
              <Text size="xl" weight={600} color="dimmed" align="center">
                {t('no_reviews_yet')}
              </Text>
              <Text size="md" color="dimmed" align="center" sx={{ maxWidth: 400 }}>
                {t('no_reviews_description')}
              </Text>
            </Stack>
          </Center>
        </Paper>
      </GradesStyle>
    );
  }

  return (
    <GradesStyle>
      <Helmet>
        <title>{t('student_grades_title')}</title>
        <meta name="description" content={t('student_grades_description')} />
      </Helmet>
      <Paper bg="transparent" p="md" mt="md">
        <Text size="xl" weight={700} align="center" mb="md">
          {t('student_grades')}
        </Text>
        <Grid>
          <Grid.Col xs={12}>
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#FE6027"
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                />
              </PieChart>
            </ResponsiveContainer>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Table striped highlightOnHover>
              <thead>
                <tr>
                  <th>Task Name</th>
                  <th>{t(`${tUnit.charAt(0).toUpperCase() + tUnit.slice(1)}`)}</th>
                  <th>{t(`${tSubUnit.charAt(0).toUpperCase() + tSubUnit.slice(1)}`)}</th>
                  <th>Score</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                {data?.completed_tasks_with_review?.reviews?.map((task) => (

                  <tr key={task.id}>
                    <td>
                      <Link 
                        to={`/${type}/${id}/${tUnit}-pathway/${task[`${tUnit}_id`]}/${tSubUnit}/${task[`${tSubUnit}_id`]}/task`}
                        style={{ 
                          color: '#029199', 
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline'
                          }
                        }}
                      >
                        {task.task_name}
                      </Link>
                    </td>
                    <td>{task[tUnit] && task[tUnit]?.slice(-1)}</td>
                    <td>{getSubUnitIndex(task[`${tUnit}_id`], task[`${tSubUnit}_id`], unitContents)}</td>
                    <td>{task.score}</td>
                    <td>{parse(`${task.comment}`)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Grid.Col>
        </Grid>
      </Paper>
    </GradesStyle>
  );
};

export default StudentGrades;

const GradesStyle = styled.div`
  max-width: 95%;
  min-height:100vh;
  margin: 20px auto 60px auto;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .chart-container {
    width: 40%;
    max-width: 400px;
    margin-bottom: 20px;
  }

  .table-container {
    width: 100%;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
    box-shadow: 0px 1.97px 6.47px 0px #00000005;

  }

  thead {
    background-color: #029199;

    tr:first-child {
      th:first-child {
        border-top-left-radius: 10px;
        color: #fff;
      }
      th:last-child {
        border-top-right-radius: 10px;
        color: #fff;
      }
    }
    tr {
      th {
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        padding: 12px;
      }
    }
  }

  tbody {
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 10px;
        background-color : #fff;
      }
      td:last-child {
        border-bottom-right-radius: 10px;
        background-color : #fff;
      }
    }
    tr {
      td {
        padding: 20px 10px;
        border-top: 1px solid #0291994A;
        border-bottom: 1px solid #0291994A;
        background-color : #fff;
      }
    }
  }
`;
